<template>
  <div class="panel">
    <div class="search-box">
      <div class="search-content">
        <p>
          <el-select
            v-model="search_platform"
            placeholder="请选择平台"
            size="small"
            clearable
            filterable
          >
            <el-option
              v-for="item of platform_options"
              :key="item.platform"
              :label="item.platform_unique"
              :value="item.platform"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-select
            v-model="search_province"
            placeholder="请选择省份"
            size="small"
            clearable
            filterable
            @change="changeProvince"
          >
            <el-option
              v-for="item of province_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-select
            v-model="search_hospital"
            placeholder="请选择医院"
            size="small"
            clearable
            filterable
            v-if="search_province == ''"
            :remote="true"
            :remote-method="remoteMethod"
            @change="changehHospital"
          >
            <el-option
              v-for="item in hospital_remote_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select
            v-model="search_hospital"
            placeholder="请选择医院"
            v-else
            size="small"
            clearable
            filterable
            :remote="false"
            @change="changehHospital"
          >
            <el-option
              v-for="item in hospital_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-input
            v-model="search_instrument"
            v-if="search_hospital == ''"
            size="small"
            clearable
            @input="inputInstrument"
            placeholder="请输入机号"
          ></el-input>
          <el-select
            v-model="search_instrument"
            v-else
            placeholder="请选择机号"
            size="small"
            clearable
            filterable
            @change="changeInstrument"
          >
            <el-option
              v-for="item of instrument_options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-select
            v-model="search_testing"
            placeholder="请选择项目"
            clearable
            size="small"
            filterable
            @change="changeTesting"
          >
            <el-option
              v-for="item of testing_options_using"
              :key="item.testing_id"
              :label="item.testing_unique"
              :value="item.testing_id"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-input
            v-model="search_engineer"
            size="small"
            clearable
            placeholder="请输入工程师"
          ></el-input>
        </p>
        <p>
          <el-input
            v-model="search_qc_batch"
            v-if="search_hospital == ''&&!instrument_input_right"
            size="small"
            clearable
            placeholder="请输入质控批号"
          ></el-input>
          <el-select
            v-model="search_qc_batch"
            v-else
            placeholder="请选择质控批号"
            size="small"
            clearable
            filterable
          >
            <el-option
              v-for="item of qc_batch_options"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-select
            v-model="search_joined"
            placeholder="请选择医联体"
            clearable
            size="small"
            filterable
          >
            <el-option
              v-for="item of joined_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </p>
        <p>
          <el-date-picker
            :picker-options="pickerBeginDateBefore"
            v-model="search_date"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
            :clearable="false"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
      </div>
      <div class="btn-box">
        <el-button type="primary" :disabled="search_loading" :loading="search_loading" class="btn" @click="confirm_screen">
          搜索
        </el-button>
      </div>
    </div>
    <div class="content-box" v-show="!search_loading">
      <div class="list-box">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item-box"
          @click="toIndoorPage(item)"
        >
          <div class="item-top">
            <p class="title">
              <img src="@/assets/img/hospital .png" alt="" />
              {{ item.hospital_name }}
            </p>
          </div>
          <div class="item-center">
            <p>项目：{{ item.testing_unique }}</p>
            <p>机号：{{ item.instrument_id }}</p>
            <p>位置：{{ item.label }}</p>
            <p>批号：{{ item.qc_batch }}</p>
          </div>
          <!-- <div class="item-bottom">
            <div class="item-bottom-item" v-for="(items,indexs) in item.data" :key="indexs">
              <span>{{items.date_time_order}}</span>
              <p><span class="C-box">C1</span><span><span :style="item.is_c1_lc?'color: red':''">{{items.c1?items.c1:'-&nbsp;&nbsp;&nbsp;&nbsp;'}}</span>mg/L</span></p>
              <p><span class="C-box">C2</span><span><span :style="item.is_c2_lc?'color: red':''">{{items.c2?items.c2:'-&nbsp;&nbsp;&nbsp;&nbsp;'}}</span>mg/L</span></p>
            </div>
          </div> -->
          <div class="item-bottom">
            <div
              :style="
                item.data[item.data.length - 1].is_c1_lc ? 'color: red' : ''
              "
            >
              C1:
              <p>
                <span>
                  {{
                    c_text( item.data[item.data.length - 1].c1)
                  }}
                </span>
              </p>
            </div>
            <div
              :style="
                item.data[item.data.length - 1].is_c2_lc ? 'color: red' : ''
              "
            >
              C2:
              <p>
                <span>
                  {{
                    c_text( item.data[item.data.length - 1].c2)

                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        v-if="pagination_show"
        :hide-on-single-page="total <= 0"
        background
        layout="total,prev, pager, next,jumper"
        :total="total"
        :page-size="size"
        :current-page.sync="page"
        @current-change="handleCurrentChange"
      />
    </div>
    <div v-if="!search_loading&&list.length === 0" class="no-data-box">
      <div>
        <img src="@/assets/img/nodata.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/quality'
import apiStatistics from '@/api/tongji'
import { search_qc_hospital } from '@/api/search.js'
import { eventBus } from '@/main'
import store from '@/store'

export default {
  name: 'Panel',
  data() {
    const now_date = new Date().toLocaleDateString('az', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    return {
      search_platform: '',
      search_province: '',
      search_hospital: '',
      search_instrument: '',
      search_testing: '',
      search_engineer: '',
      search_qc_batch: '',
      search_date: now_date,
      // search_date: '2024-03-05',
      search_joined: '',
      joined_options: [],
      province_options: JSON.parse(localStorage.getItem('province')), //地区下拉框
      platform_options: [],
      hospital_options: [],
      hospital_remote_options: [],
      instrument_options: [],
      qc_batch_options: [],
      testing_options: [],
      all_testing_option: [
        { testing_id: 4, testing_unique: 'hs-CRP' },
        { testing_id: 11, testing_unique: 'SAA' },
      ],
      instrument_input_right: false, //机号输入的正确，且获取到了质控品
      province_hospital_list: [], //根据省份分类的医院列表
      is_province_hospital_list_called: false, //请求医院接口调用后就不再调用
      all_qc_sumer_list: [], //当前所选医院的所有质控品
      pickerBeginDateBefore: {
        // 最大选择当前日
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      pagination_show: true,
      total: 10,
      page: 1,
      size: 12,
      now_date,
      list: [],
      search_loading: false, //加载状态
      is_search_old: false, //搜索条件是否设为之前保存的版本
    }
  },
  computed: {
    testing_options_using:function() {
      return this.search_hospital||this.instrument_input_right? this.testing_options : this.all_testing_option
    },
    // 质控数值显示
    c_text: function() {
      return (c_data)=> {
        return c_data? Number(c_data).toFixed(2) + '\u00a0mg/L' : '\u00a0\u00a0——\u00a0\u00a0'
      }
    }

  },
  activated (){
    store.commit('cache', { name: 'Panel', type: 0 })
  },
  created() {
    eventBus.$on('searchOld',(value) => {
      this.is_search_old = true;
    })
    let loading = this.$loading({
        lock: true,
        text: '数据搜索中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    this.search_loading = true
    setTimeout(()=> {
      if( this.is_search_old){
        // 设为之前保存的
        let old_search_data = JSON.parse(localStorage.getItem('old_search_data'))
        this.search_platform = old_search_data.platform
        this.search_province = old_search_data.province
        this.search_hospital = old_search_data.hospital
        this.search_instrument = old_search_data.instrument
        this.search_testing = old_search_data.testing
        this.search_engineer = old_search_data.engineer
        this.search_qc_batch = old_search_data.qc_batch
        this.search_date = old_search_data.search_date
        this.size = old_search_data.size
        this.page = old_search_data.page
        this.pagination_show = false
        setTimeout(()=> {
          this.pagination_show = true
        },500)
        if(this.search_hospital){
          this.getQcSerumInfo();
          this.hospital_options = [{
            value: this.search_hospital,
            label: old_search_data.search_hospital_name
          }]
        }

      }
      this.platformFun()
      this.getListFun()
    },500)
  },
  beforeDestroy(){
    //移除监听事件"msg"
    eventBus.$off("searchOld")
  },
  methods: {
    // 医院远程搜索
    remoteMethod(e) {
      if(e){
        this.hospital_remote_options = search_qc_hospital({ hospital_name: e })
      }
    },
    //平台下拉框
    platformFun() {
      apiStatistics.platform({}).then((res) => {
        this.platform_options = res.data.data
      })
    },
    // 切换省份
    async changeProvince(val) {
      if (!this.is_province_hospital_list_called) {
        this.is_province_hospital_list_called = true
        let res = await api.qc_hospital_list()
        this.province_hospital_list = res.data.data
      }
      if(val){
        this.hospital_options = this.province_hospital_list
        .find((e) => val == e.province_id).hospital_list.map((e) => {
          return {
            value: e.hospital_id,
            label: e.hospital_name,
          }
        })
      }
      if (
        this.search_hospital &&
        !this.hospital_options.find(e => e.value == this.search_hospital)
      ) {
        this.search_hospital = ''
        this.search_instrument = ''
        this.search_testing = ''
        this.search_qc_batch = ''
      }else if(this.search_hospital == '') {
        this.search_hospital = ''
        this.search_instrument = ''
        this.search_testing = ''
        this.search_qc_batch = ''
      }
    },
    /* 根据医院获取质控品列表信息并处理 */
    changehHospital() {
      if (this.search_hospital != '') {
        this.getQcSerumInfo()
      }
    },
    getQcSerumInfo() {
      api.qc_serum_info({ hospital_id: this.search_hospital }).then((res) => {
        let all_qc_info_list = [] //所有质控品直接显示
        res.data.forEach((temp) => {
          temp.qs_list.forEach((temp2) => {
            all_qc_info_list = all_qc_info_list.concat(temp2.qc_serum_info)
          })
        })
        this.all_qc_sumer_list = all_qc_info_list.map((e) => {
          let qc_serum_id_text_list = e.qc_serum_id.split('-')
          return {
            instrument: qc_serum_id_text_list[0],
            testing: qc_serum_id_text_list[1],
            qc_batch: qc_serum_id_text_list[2],
          }
        })
        // 机号可选列表
        this.setInstrument()
        if (
          this.search_instrument &&
          !this.instrument_options.includes(this.search_instrument)
        ) {
          this.search_instrument = ''
        }
        // 项目可选列表
        this.setTesting()
        if (
          this.search_testing &&
          !this.testing_options.find((e) => e.testing_id == this.search_testing)
        ) {
          this.search_testing = ''
        }
        // 质控批号可选列表
        this.setQcBatch()
        if (
          this.search_qc_batch &&
          !this.qc_batch_options.includes(this.search_qc_batch)
        ) {
          this.search_qc_batch = ''
        }
      })
    },
    // 切换机号
    changeInstrument() {
      // 项目可选列表
      this.setTesting()
      if (
        this.search_testing &&
        !this.testing_options.find((e) => e.testing_id == this.search_testing)
      ) {
        this.search_testing = ''
      }
      // 质控批号可选列表
      this.setQcBatch()
      if (
        this.search_qc_batch &&
        !this.qc_batch_options.includes(this.search_qc_batch)
      ) {
        this.search_qc_batch = ''
      }
    },
    // 切换项目
    changeTesting() {
      // 质控批号可选列表
      this.setQcBatch()
      if (
        this.search_qc_batch &&
        !this.qc_batch_options.includes(this.search_qc_batch)
      ) {
        this.search_qc_batch = ''
      }
    },
    // 设置机号可选列表
    setInstrument() {
      let instrument_options = this.all_qc_sumer_list.map((e) => {
        return e.instrument
      })
      this.instrument_options = Array.from(new Set(instrument_options))
    },
    // 设置项目可选列表
    setTesting() {
      // if (this.search_hospital == '') {
      //   //医院为空，直接选择
      //   this.testing_options = this.all_testing_option
      //   return
      // }
      let testing_options = []
      if (this.search_instrument) {
        testing_options = this.all_qc_sumer_list
          .filter((e) => e.instrument === this.search_instrument)
          .map((e) => {
            return Number(e.testing)
          })
      } else {
        testing_options = this.all_qc_sumer_list.map((e) => {
          return Number(e.testing)
        })
      }
      
      testing_options = Array.from(new Set(testing_options))

      this.testing_options = this.all_testing_option.filter((e) =>
        testing_options.includes(e.testing_id),
      )
    },
    // 设置质控批号可选列表
    setQcBatch() {
      let qc_batch_options = this.all_qc_sumer_list.filter((e) => {
        let determine1 = true,
          determine2 = true
        if (this.search_instrument) {
          determine1 = e.instrument == this.search_instrument
        }
        if (this.search_testing) {
          determine2 = e.testing == this.search_testing
        }
        return determine1 && determine2
      })

      qc_batch_options = qc_batch_options.map((e) => {
        return e.qc_batch
      })
      this.qc_batch_options = Array.from(new Set(qc_batch_options))
    },
    // 输入机号后请求
    inputInstrument(val) {
      if(val.length == 8){
        let instrument_id = val
        api.serum_data_list({instrument_id}).then(res => {
          if(res.data.length!=0){
            this.instrument_input_right = true;
            this.all_qc_sumer_list = res.data.map((e) => {
              let qc_serum_id_text_list = e.qc_serum_id.split('-')
              return {
                instrument: qc_serum_id_text_list[0],
                testing: qc_serum_id_text_list[1],
                qc_batch: qc_serum_id_text_list[2],
              }
            })
            // 项目可选列表
            this.setTesting()
            if (
              this.search_testing &&
              !this.testing_options.find((e) => e.testing_id == this.search_testing)
            ) {
              this.search_testing = ''
            }
            // 质控批号可选列表
            this.setQcBatch()
            if (
              this.search_qc_batch &&
              !this.qc_batch_options.includes(this.search_qc_batch)
            ) {
              this.search_qc_batch = ''
            }
          }else{
            
          }
        })
      }else{
        this.instrument_input_right = false;
      }
      
    },
    // 切换页码
    handleCurrentChange(val) {
      this.page = val
      this.getListFun()
    },
    // 确认搜索
    confirm_screen() {
      this.page = 1
      this.getListFun()
    },
    // 获取数据
    getListFun() {
      let param = {
        platform: this.search_platform,
        province: this.search_province,
        hospital: this.search_hospital,
        instrument: this.search_instrument,
        testing: this.search_testing,
        engineer: this.search_engineer,
        qc_batch: this.search_qc_batch,
        search_date: this.search_date,
        size: this.size,
        page: this.page,
      }
      let search_hospital_name = ""
      if(this.search_hospital){
        let temp_hospital_options = this.search_province==''? this.hospital_remote_options: this.hospital_options
        search_hospital_name =temp_hospital_options.find(e => e.value == this.search_hospital).label
      }
      localStorage.setItem('old_search_data', JSON.stringify({...param,search_hospital_name}))

      let loading = this.$loading({
        lock: true,
        text: '数据搜索中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.search_loading = true
    
      api
        .qc_panel(param)
        .then((res) => {
          if (res.code === 200) {
            this.total = res.data.count
            this.list = res.data.results
          }
        })
        .finally((msg) => {
          loading.close()
          this.search_loading = false
        })
    },

    // 跳转到室内质控
    toIndoorPage(item) {
      let query = {
        hospital_id: item.hospital_id,
        hospital_name: item.hospital_name,
        qc_serum_id: item.qc_serum_id,
        panel_time: item.data[0].date_time_order.split('.')[0]
      }
      this.$router.push({ path: '/Indoor', query })
      store.commit('cache', { name: 'Panel', type: 1 })

    },
  },
}
</script>

<style lang="scss" scoped>
.panel {
  padding: $gap;
}
.search-box {
  padding: $gap;
  display: flex;
  background-color: #fff;
  .search-content {
    display: flex;
    flex-wrap: wrap;
    gap: $gap;
    & > p {
    }
  }
  .btn-box {
    width: px(800);
    position: relative;

    .btn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: px(120);
    }
  }
}
.no-data-box {
  height: px(400);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #888;
  &>div {
    &>p {
      margin-top: px(20);
    }
  }
}
.content-box {
  margin-top: 2 * $gap;
}

.list-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2 * $gap;
}
.item-box {
  width: calc((100% - 15 * $gap) / 4);
  background-color: #fff;
  border-radius: px(5);
  padding: $gap;
  cursor: pointer;
  .item-top {
    border-bottom: 1px solid #ccc;
    padding-bottom: px(5);
    font-size: px(20);
    padding: $gap $gap 1.5 * $gap;
    .title {
      display: flex;
      align-content: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      img {
        height: px(25);
        width: px(25);
        margin-right: $gap;
      }
    }
  }
  .item-center {
    font-size: px(17);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    padding: $gap;
    & > p {
      width: 45%;
    }
    & > p:first-child {
      margin-bottom: 1.5 * $gap;
    }
    & > p:nth-child(2) {
      margin-bottom: 1.5 * $gap;
    }
  }
  .item-bottom {
    padding: $gap;
    display: flex;
    justify-content: space-between;

    //.item-bottom-item {
    //  font-size: px(17);
    //  color: #777;
    //  display: flex;
    //  justify-content: space-between;
    //  gap: $gap;
    //  margin-top: px(3);
    //  &>span {
    //    flex: 3;
    //  }
    //  &>p {
    //    flex: 4;
    //    display: flex;
    //    justify-content: space-between;
    //  }
    //  .C-box {
    //    display: inline-block;
    //    background-color: #00A2E5;
    //    color: #fff;
    //    padding: px(2);
    //    font-weight: bold;
    //    border-radius: px(5);
    //  }
    //}
    div {
      padding-top: 1.5 * $gap;
      display: flex;
      align-items: baseline;
      font-size: px(22);
      line-height: px(35);
      & > p {
        margin-left: px(5);
        font-size: px(17);
        display: inline-block;
        & > span {
          display: inline-block;
          text-align: right;
        }
      }
    }
  }
}
.item-box:hover {
  background-color: #f9fefe;
}
</style> 